import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PaymentSuccess = () => (
  <Layout>
    <SEO title="Payment Successful" />
    <h1 className="title">PURCHASE SUCCESSFUL</h1>
    <i class="fas fa-shipping-fast"></i>
    <p className="bandDescription">
      Thanks for your purchase! We received your order which will arrive in 10-12 business days.
      If you have any questions, email us at dropdiver@gmail.com
    </p>
  </Layout>
)

export default PaymentSuccess